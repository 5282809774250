<template>
  <v-app>
    <!-- <core-system-bar /> -->

    <core-app-bar v-if="($route.name == 'Select') | ($route.name == 'Home')" />
    <!-- <core-banner /> -->

    <blank-view />
  </v-app>
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: 'LayoutsBlank',
  components: {
    CoreAppBar: () => import('./AppBar.vue'),
    // BlankFooter: () => import("./Footer"),
    BlankView: () => import('./View.vue')
  },
  computed: {
    isLoggedin: get('auth/isLoggedin')
  }
};
</script>
